<template>
  <div v-loading="loading">
    <div ref="screen" class="input">
      <!--    顶部选项按钮 开始-->
      <el-row>
        <el-button type="primary" @click="changShifts" size="mini" v-if="authe.Hotel_Core_TakeOver_ShiftManagement">交班管理</el-button>&nbsp;&nbsp;
          <el-dialog title="交班管理" :visible.sync="shift.dialogShift" @close="closeDialogShift" :close-on-click-modal="false" width="920px" class="dialog">
            <el-tabs v-model="shift.tab" @tab-click="tabChange">
              <el-tab-pane label="交接班" name="first">
                <el-form inline :label-width="screenLabelWidth" :model="shift.changShifts" :rules="shift.changShifts.rules" ref="jsrHandInAmount">
                  <el-row>
                    <el-col :span="12" style="padding-right: 5px;">
                      <el-divider content-position="left">交班信息</el-divider>
                        <el-row>
                          <el-form-item label="起始时间">
                            <el-input v-model="shift.changShifts.params.jsrStartTime" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="结束时间">
                            <el-input v-model="shift.changShifts.params.jsrEndTime" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                        </el-row>
                        <el-row>
                          <el-form-item label="开房数">
                            <el-input v-model="shift.changShifts.params.jsrCheckInNums" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="退房数">
                            <el-input v-model="shift.changShifts.params.jsrCheckOutNums" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                        </el-row>
                        <el-row>
                          <el-form-item label="总押金">
                            <el-input v-model="shift.changShifts.params.jsrRecordedDepositAmount" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="总收入">
                            <el-input v-model="shift.changShifts.params.jsrTotalIncome" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                        </el-row>
                        <el-row>
                          <el-form-item label="实际收入">
                            <el-input v-model="shift.changShifts.params.jsrRealIncome" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="挂账金额">
                            <el-input v-model="shift.changShifts.params.jsrOnAccount" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                        </el-row>
                        <el-row>
                          <el-form-item label="会员卡支付">
                            <el-input v-model="shift.changShifts.params.jsrMembersCardPay" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="商品消费">
                            <el-input v-model="shift.changShifts.params.jsrCommodityConsumption" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                        </el-row>
                        <el-row>
                          <el-col :span="12" style="padding-right: 5px">
                            <el-divider content-position="left">接班信息</el-divider>
                              <el-form-item label="交班人">
                                <el-input v-model="shift.changShifts.params.endWorkerName" disabled size="mini" class="screenInput"></el-input>
                              </el-form-item>
                              <el-form-item label="接班人">
                                <el-cascader
                                :options="userNameList"
                                @change="selectUser"
                                v-model="shift.changShifts.params.startWokerIds"
                                :props="{ expandTrigger: 'hover' }"
                                :show-all-levels="false"
                                size="mini"
                                class="screenInput">
                                </el-cascader>
                              </el-form-item>
                              <!--<el-form-item label="密码">
                                <el-input v-model="shift.changShifts.params.password" size="mini" class="screenInput"></el-input>
                              </el-form-item>-->
                              <el-form-item label="备注">
                                <el-input v-model="shift.changShifts.params.jsrRemark" type="textarea" rows="6" size="mini" class="screenInput"></el-input>
                              </el-form-item>
                          </el-col>
                          <el-col :span="12" style="padding-left: 5px">
                            <el-divider content-position="left">在线押金构成</el-divider>
                              <el-table
                              :data="shift.changShifts.depositComposition"
                              ref="highLightRow"
                              border
                              highlight-current-row
                              height="237px"
                              size="mini">
                                <el-table-column prop="depositPayType" label="款项类型" min-width="50px"></el-table-column>
                                <el-table-column prop="deposit" label="金额" show-overflow-tooltip min-width="50px"></el-table-column>
                              </el-table>
                          </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="12" style="padding-left: 5px;">
                      <el-divider content-position="left">交班金额</el-divider>
                        <el-row>
                          <el-form-item label="入账押金">
                            <el-input v-model="shift.changShifts.params.jsrRecordedDepositAmount" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="其他收入">
                            <el-input v-model="shift.changShifts.params.jsrOtherIncome" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                        </el-row>
                        <el-row>
                          <el-form-item label="退还押金">
                            <el-input v-model="shift.changShifts.params.jsrReturnDepositAmount" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="其他支出">
                            <el-input v-model="shift.changShifts.params.jsrOtherPay" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                        </el-row>
                        <el-row>
                          <el-form-item label="本班备用">
                            <el-input v-model="shift.changShifts.params.jsrPettyCash" @change="calculateAmount" size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="上班结余">
                            <el-input v-model="shift.changShifts.params.jsrWorkBalance" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                        </el-row>
                        <el-row>
                          <!--<el-form-item label="上交金额">
                            <el-input v-model="shift.changShifts.params.jsrHandInAmount" @change="calculateAmount" size="mini" class="screenInput"></el-input>
                          </el-form-item>-->
                          <el-form-item label="上交金额" prop="jsrHandInAmount">
                            <el-input v-model="shift.changShifts.params.jsrHandInAmount" size="mini" class="screenInput"></el-input>
                          </el-form-item>
                          <el-form-item label="交接金额">
                          <el-input v-model="shift.changShifts.params.jsrTransferAmount" disabled size="mini" class="screenInput"></el-input>
                          </el-form-item>
                        </el-row>
                      <el-divider content-position="left">交接金额构成</el-divider>
                        <el-table
                        :data="shift.changShifts.dataList2"
                        ref="highLightRow"
                        border
                        highlight-current-row
                        height="288px"
                        size="mini">
                          <el-table-column prop="jsrTime" label="类型" min-width="50px"></el-table-column>
                          <el-table-column prop="jsrTime" label="上班结余" min-width="50px"></el-table-column>
                          <el-table-column prop="jsrTime" label="本班收入" min-width="50px"></el-table-column>
                          <el-table-column prop="jsrTime" label="上交" min-width="50px"></el-table-column>
                          <el-table-column prop="jsrTime" label="结余" min-width="50px"></el-table-column>
                        </el-table>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="3" style="padding-left: 20px;">
                      <i class="el-icon-s-opportunity" style="font-size: 86px"></i>
                    </el-col>
                    <el-col :span="21">
                      <p>1、上班结余=本班备用金+入账押金+其他收入-退还押金-其他支出</p><br>
                      <p>2、交接金额=结余余额-上交金额</p><br>
                      <p>3、本班备用金是指本班次中附加的备用金总金额</p>
                    </el-col>
                  </el-row>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="营业状况记录" name="second">
                <el-row>
                  <el-form inline :label-width="screenLabelWidth">
                    <el-form-item label="开房数">
                      <el-input v-model="shift.changShifts.params.jsrCheckInNums" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="退房数">
                      <el-input v-model="shift.changShifts.params.jsrCheckOutNums" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="平均房价">
                      <el-input v-model="shift.business.averageHousePrice" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="在住押金">
                      <el-input v-model="shift.business.livingDeposit" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-form>
                </el-row>
                <div style="text-align:center;">
                  <!--    表格显示列表 开始-->
                  <el-table
                  :data="shift.business.dataList"
                  ref="highLightRow"
                  highlight-current-row
                  height="336px"
                  style="width:100%;">
                    <el-table-column prop="popName" label="姓名" min-width="30px"></el-table-column>
                    <el-table-column prop="address" label="地址" show-overflow-tooltip min-width="50px"></el-table-column>
                    <el-table-column prop="jsrcBeginTime" label="入住日期" show-overflow-tooltip min-width="50px"></el-table-column>
                    <el-table-column prop="jsrcEndTime" label="退房日期" min-width="50px"></el-table-column>
                    <el-table-column prop="jsrcAmountPayable" label="房费" min-width="25px"></el-table-column>
                    <el-table-column prop="jsrcDeposit" label="押金" min-width="25px"></el-table-column>
                    <el-table-column prop="jsrcTotalPrice" label="实际收入" min-width="25px"></el-table-column>
                    <el-table-column prop="jsrcState" label="状态" min-width="20px"></el-table-column>
                  </el-table>
                  <!--    表格显示列表 结束-->

                  <!--    分页 开始-->
                  <el-pagination
                    background layout="total, sizes, prev, pager, next, jumper"
                    :page-count="shift.business.pages"
                    :page-size="shift.business.params.limit"
                    :total="shift.business.total"
                    :current-page="shift.business.params.cursor"
                    @current-change="businessCurrentChange"
                    @size-change="businessSizeChange"
                    :page-sizes="[5,10,20,40]"
                  ></el-pagination>
                  <!--    分页 结束-->
                </div>
              </el-tab-pane>
            </el-tabs>
            <div slot="footer">
              <el-button type="primary" icon="el-icon-printer" @click="printClick">打 印</el-button>
              <el-button type="primary" icon="el-icon-check" @click="doShiftExchange">交班</el-button>
              <el-button @click="shift.dialogShift = false">取 消</el-button>
            </div>
          </el-dialog>
        <!--    打印票据弹窗 开始-->
        <el-dialog title="打印票据" :visible.sync="print.printDialog" @close="printDialogClose" append-to-body :close-on-click-modal="false" width="900px"  class="screen printhistory">
          <div id="print" style="width: 99%; height:99%;">
            <div class="print">
              <div class="firstTable">
                <table cellspacing="0" cellpadding="0">
                  <caption>酒店交班资料单</caption>
                  <tbody>
                    <tr>
                      <td style="width: 250px " ><span>交班时间：{{shift.changShifts.params.jsrEndTime}}</span></td>
                      <td style="width: 250px " ><span>交班账号：{{shift.changShifts.params.endWorkerName}}</span></td>
                      <td style="width: 250px " ><span>接班账号：{{shift.changShifts.params.startWokerIds}}</span></td>
                    </tr>
                    <tr>
                      <td style="width: 250px " ><span>上班结余：{{shift.changShifts.params.jsrWorkBalance}}</span></td>
                      <td style="width: 250px " ><span>入账押金：{{shift.changShifts.params.jsrRecordedDepositAmount}}</span></td>
                      <td style="width: 250px " ><span>出账押金：{{shift.changShifts.params.jsrReturnDepositAmount}}</span></td>
                    </tr>
                    <tr>
                      <td style="width: 250px " ><span>其他收入：{{shift.changShifts.params.jsrOtherIncome}}</span></td>
                      <td style="width: 250px " ><span>其他支出：{{shift.changShifts.params.jsrOtherPay}}</span></td>
                      <td style="width: 250px " ><span>备用金额：{{shift.changShifts.params.jsrPettyCash}}</span></td>
                    </tr>
                    <tr>
                      <td style="width: 250px " ><span>上交金额：{{shift.changShifts.params.jsrHandInAmount}}</span></td>
                      <td style="width: 250px " ><span>交接金额：{{shift.changShifts.params.jsrTransferAmount}}</span></td>
                      <td style="width: 250px " ><span>备注信息：{{shift.changShifts.params.jsrRemark}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="secondTable">
                <table cellspacing="0" cellpadding="0" class="secondTable">
                  <tbody>
                    <tr>
                      <th style="width: 250px ">项目</th>
                      <th style="width: 250px ">收押金额</th>
                      <th style="width: 250px ">退押金额</th>
                      <th style="width: 250px ">挂账金额</th>
                      <th style="width: 250px ">收入金额</th>
                      <th style="width: 250px ">支出金额</th>
                    </tr>
                    <tr>
                      <th style="width: 250px ">金额</th>
                      <td style="width: 250px ">{{shift.changShifts.params.jsrRecordedDepositAmount}}</td>
                      <td style="width: 250px ">{{shift.changShifts.params.jsrReturnDepositAmount}}</td>
                      <td style="width: 250px ">{{shift.changShifts.params.jsrOnAccount}}</td>
                      <td style="width: 250px ">{{shift.changShifts.params.jsrRealIncome}}</td>
                      <td style="width: 250px ">{{shift.changShifts.params.jsrReturnDepositAmount + shift.changShifts.params.jsrOtherPay}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="thirdTable">
                <span>交班人签名：__________</span>
                <span>接班人签名：__________</span>
              </div>
            </div>
          </div>
          <el-row style="text-align: center;margin-top: 10px">
            <el-button type="primary" v-print="'#print'">打印</el-button>
          </el-row>
        </el-dialog>
        <!--    打印票据弹窗 结束-->
      </el-row>
      <el-row class="screen">
        <el-form :label-width="screenLabelWidth">
          <el-form-item label="交班时间">
            <el-date-picker
              v-model="data.time"
              @change="dateChange"
              :unlink-panels="true"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:00"
              filterable
              clearable
              size="mini"
              :picker-options="data.pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-row>
      <!--    顶部选项按钮 结束-->
    </div>
    <!--    数据列表 开始-->
    <div style="text-align:center;">
      <!--    表格显示列表 开始-->
      <el-table
      :data="dataList"
      ref="highLightRow"
      highlight-current-row
      :height="tableHeight"
      style="width:100%;">
        <el-table-column  align="center" prop="jsrTime" label="日期" min-width="60px"></el-table-column>
        <el-table-column  align="center" prop="jsrStartTime" label="当班时间" show-overflow-tooltip min-width="70px"></el-table-column>
        <el-table-column  align="center" prop="jsrEndTime" label="交班时间" show-overflow-tooltip min-width="70px"></el-table-column>
        <el-table-column  align="center" prop="jsrEndWorker" label="交班者" min-width="50px"></el-table-column>
        <el-table-column  align="center" prop="jsrStartWorker" label="接班者" min-width="50px"></el-table-column>
        <el-table-column  align="center" prop="jsrRecordedDepositAmount" label="入账押金" min-width="50px"></el-table-column>
        <el-table-column  align="center" prop="jsrReturnDepositAmount" label="出账金额" min-width="50px"></el-table-column>
        <el-table-column  align="center" prop="jsrWorkBalance" label="上班结余" min-width="50px"></el-table-column>
        <el-table-column  align="center" prop="jsrPettyCash" label="备用金" min-width="50px"></el-table-column>
        <el-table-column  align="center" prop="jsrTransferAmount" label="交接金额" min-width="50px"></el-table-column>
        <el-table-column  align="center" prop="jsrOtherIncome" label="其他收入" min-width="50px"></el-table-column>
        <el-table-column  align="center" prop="jsrOtherPay" label="其他支出" min-width="50px"></el-table-column>
        <el-table-column  align="center" prop="jsrCheckInNums" label="开房数" min-width="50px"></el-table-column>
        <el-table-column  align="center" prop="jsrCheckOutNums" label="退房数" min-width="50px"></el-table-column>
      </el-table>
      <!--    表格显示列表 结束-->

      <!--    分页 开始-->
      <el-pagination
        background layout="total, sizes, prev, pager, next, jumper"
        :page-count="data.pages"
        :page-size="data.params.limit"
        :total="data.total"
        :current-page="data.params.cursor"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[5,10,20,40]"
      ></el-pagination>
      <!--    分页 结束-->
    </div>
    <!--    数据列表 结束-->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import moment from "moment";
export default {
  name: 'changeShifts',
  data(){
    let checkHandInAmount = (rule, value, callback) => {
      //上交金额
      let jsrHandInAmount = this.shift.changShifts.params.jsrHandInAmount!=''?parseFloat(this.shift.changShifts.params.jsrHandInAmount):0
      //上班结余
      let jsrWorkBalance = this.shift.changShifts.params.jsrWorkBalance!=''?parseFloat(this.shift.changShifts.params.jsrWorkBalance):0
      if(jsrHandInAmount > jsrWorkBalance){
        callback(new Error('上交金额不能大于结余金额'))
      }else{
        //交接金额
        let jsrTransferAmount = jsrWorkBalance - jsrHandInAmount;
        this.shift.changShifts.params.jsrTransferAmount = jsrTransferAmount;
      }
    }
    return{
      loading: false,
      screenState: false,
      screenLabelWidth: '75px',
      tableHeight: window.innerHeight - 210,
      shift: {
        dialogShift: false,
        tab: 'first',

        changShifts: {
          rules: {
            jsrHandInAmount: [
              {validator: checkHandInAmount,trigger: 'blur'}
            ],
          },
          params: {
            jsrEndWorker: 1,
            jsrStartTime: '',
            jsrEndTime: '',
            jsrCheckInNums: 0,
            jsrCheckOutNums: 0,
            jsrTotalIncome: 0,
            jsrRealIncome: 0,
            jsrOnAccount: 0,
            jsrMembersCardPay: 0,
            jsrCommodityConsumption: 0,
            jsrRecordedDepositAmount: 0,
            jsrOtherIncome: 0,
            jsrDepositInResidence: 0,
            jsrReturnDepositAmount: 0,
            jsrOtherPay: 0,
            jsrStartWorker: null,
            jsrPettyCash: 0,
            jsrWorkBalance: 0,
            jsrHandInAmount: 0,
            jsrTransferAmount: 0,
            endWorkerName: '',
            jsrRemark: ''
          },
          startWokerIds:'',
          depositComposition: [],
          dataList2: [],
        },
        business: {
          params: {
            startTime: '',
            endTime: '',
            limit: 10,//每页的条数
            cursor: 1,//初始页
          },
          averageHousePrice: 0.0,
          livingDeposit: 0.0,
          total: 0,//总条数
          pages: 0,//每页数据
          dataList: [],
        },
      },
      print: {
        printDialog: false,
        nowTime: ''
      },
      data: {//数据分页查询
        params: {
          jsrStartTime: '',
          jsrEndTime: '',
          limit: 10,//每页的条数
          cursor: 1,//初始页
        },
        total: 0,//总条数
        pages: 0,//每页数据
        time: '',
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      },
      dataList: [],
    }
  },
  computed: {
    ...mapState(['userNameList','authe'])
  },
  created(){
    this.getDataList()
  },
  mounted(){
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - this.$refs.screen.clientHeight - 140
    })
  },
  methods: {
    printClick(){
      this.print.nowTime = moment().format('YYYY-MM-DD HH:mm:ss')
      this.print.printDialog = true
    },
    printDialogClose(){
      
    },
    selectUser(){
      this.shift.changShifts.params.jsrStartWorker = parseInt(this.shift.changShifts.params.startWokerIds[2])
    },
  //查询数据
    getDataList(){
      this.$axios({
        method: 'get',
        url: '/hotel/shiftRecord',
        params: this.data.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          this.dataList = res.data.result.records
          this.data.total = res.data.result.total
        } else {
          this.dataList = []
          this.data.total = 0
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    handleCurrentChange(cursor){//点击更改点前页数
      this.data.params.cursor = cursor;
      this.getDataList()
    },
    handleSizeChange(limit){//点击更改显示条数
      this.data.params.limit = limit;
      this.getDataList()
    },
    dateChange(){//日期改变时查询
      if(this.data.time == null) {
        this.data.params.jsrStartTime = ''
        this.data.params.jsrEndTime = ''
        this.data.params.cursor = 1
        this.getDataList()
      }else{
        this.data.params.jsrStartTime = `${this.data.time[0]}`
        this.data.params.jsrEndTime = `${this.data.time[1]}`
        this.data.params.cursor = 1
        this.getDataList()
      }
    },
    //交班管理
    tabChange(tab){
      if(tab){
        if(tab.name == 'first'){
          this.changShifts()
        }else if(tab.name == 'second'){
          this.business()
        }
      }else{
        this.changShifts()
      }
    },
    //交接班
    changShifts(){
      this.$axios({
        method: 'get',
        url: '/hotel/shiftRecord/getShiftRecord'
      }).then(res => {
        for(let i in res.data.result){
          for(let j in this.shift.changShifts.params){
            if(i == j){
              this.shift.changShifts.params[j] = res.data.result[i]
            }
          }
        }
        // this.shift.business.params.startTime = '2019-05-04 00:00:00'
        // this.shift.business.params.endTime = '2020-01-04 00:00:00'
        this.shift.business.params.startTime = res.data.result.jsrStartTime
        this.shift.business.params.endTime = res.data.result.jsrEndTime
        this.shift.changShifts.depositComposition = res.data.result.depositComposition
        this.shift.dialogShift = true
      }).catch(err => {
        this.shift.dialogShift = false
        this.isError(err,this)
      })
    },
    closeDialogShift(){
      this.resetForm(['jsrHandInAmount'],this)
      this.shift.tab = 'first'
      this.shift.changShifts.params= {
        jsrEndWorker: '',
        jsrStartTime: '',
        jsrEndTime: '',
        jsrCheckInNums: 0,
        jsrCheckOutNums: 0,
        jsrTotalIncome: 0,
        jsrRealIncome: 0,
        jsrOnAccount: 0,
        jsrMembersCardPay: 0,
        jsrCommodityConsumption: 0,
        jsrRecordedDepositAmount: 0,
        jsrOtherIncome: 0,
        jsrReturnDepositAmount: 0,
        jsrOtherPay: 0,
        jsrStartWorker: '',
        jsrPettyCash: 0,
        jsrWorkBalance: 0,
        jsrHandInAmount: 0,
        jsrTransferAmount: 0,
        endWorkerName: '',
      }
    },
    //营业情况记录
    business(){
      this.$axios({
        method: 'get',
        url: '/hotel/shiftRecord/getContract',
        params: this.shift.business.params
      }).then(res => {
        if (res.data.result.records.length > 0) {
          if(res.data.result.records.length == 1 && res.data.result.records[0].jsrcId == null){
            this.shift.business.dataList = []
            this.shift.business.total = 0
            return
          }
          this.shift.business.dataList = res.data.result.records;
          for(let i of this.shift.business.dataList){
            let a = i.hsAddCommunity ? `${i.hsAddCommunity}\u3000` : ''
            let b = i.hsRoomType ? `${i.hsRoomType}\u3000` : ''
            let c = i.hsAddDoorplateno ? `${i.hsAddDoorplateno}` : ''
            i.address = a + b + c
          }
          this.shift.business.averageHousePrice = res.data.result.records[0].averageHousePrice;
          this.shift.business.livingDeposit = res.data.result.records[0].livingDeposit;
          this.shift.business.total = res.data.result.total
        } else {
          this.shift.business.dataList = []
          this.shift.business.total = 0
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    businessCurrentChange(cursor){//点击更改点前页数
      this.shift.business.params.cursor = cursor;
      this.business()
    },
    businessSizeChange(limit){//点击更改显示条数
      this.shift.business.params.limit = limit;
      this.business()
    },
    calculateAmount(){
      //其他支出
      let jsrOtherPay = this.shift.changShifts.params.jsrOtherPay!=''?parseFloat(this.shift.changShifts.params.jsrOtherPay):0
      //退还押金
      let jsrReturnDepositAmount = this.shift.changShifts.params.jsrReturnDepositAmount!=''?parseFloat(this.shift.changShifts.params.jsrReturnDepositAmount):0
      //其他收入
      let jsrOtherIncome = this.shift.changShifts.params.jsrOtherIncome!=''?parseFloat(this.shift.changShifts.params.jsrOtherIncome):0
      //入账押金
      let jsrRecordedDepositAmount = this.shift.changShifts.params.jsrRecordedDepositAmount!=''?parseFloat(this.shift.changShifts.params.jsrRecordedDepositAmount):0
      //本班备用
      let jsrPettyCash = this.shift.changShifts.params.jsrPettyCash!=''?parseFloat(this.shift.changShifts.params.jsrPettyCash):0
      //上交金额
      let jsrHandInAmount = this.shift.changShifts.params.jsrHandInAmount!=''?parseFloat(this.shift.changShifts.params.jsrHandInAmount):0
      //上班结余
      let jsrWorkBalance = jsrPettyCash + jsrRecordedDepositAmount + jsrOtherIncome - jsrReturnDepositAmount - jsrOtherPay;
      //交接金额
      this.shift.changShifts.params.jsrWorkBalance = jsrWorkBalance;
    },
    //执行交班
    doShiftExchange(){
      this.$axios({
        method: 'post',
        url: '/hotel/shiftRecord',
        data: this.shift.changShifts.params
      }).then(res => {
        this.shift.dialogShift = false
        this.data.params.cursor = 1
        this.getDataList();
      }).catch(err => {
        this.shift.dialogShift = true
        this.isError(err,this)
      })
    }
  },
}
</script>
<style lang="scss" scoped>
  .screenInput{
    width: 125px;
  }
  .dialog >>> .el-dialog__body{
    height: 490px;
    overflow: auto;
  }
  p{
    margin: 0;
    padding-left: 10px;
    font-size: 14px;
  }
  .print{
    width:100%;
    margin: 0 auto;
    .firstTable{
      padding: 10px;
      border-bottom: 2px solid #aaa;
      table{
        width: 100%;
        font-size:14px;
        caption{
          font-weight: 600;
          font-size: 20px;
          padding: 10px 0 20px;
        }
        td{
          padding: 5px 0px;
          span{
            font-weight: 600;
            padding: 5px 0px;
          }
        } 
      }
    }
    .secondTable{
      padding: 10px;
      border-bottom: 2px solid #aaa;
      table{
        width: 100%;
        padding: 0;
        font-size:14px;
        border: solid #333; 
        border-width:1px 0px 0px 1px;
        tr{
          text-align:center;
            border:1px solid #333;
            margin: 0;
            border-radius: 0;
          th,td{
            border: solid #333;
            margin: 0;
            border-width:0px 1px 1px 0px;
            padding:5px 0px;
          }
        }
      }
    }
    .thirdTable{
      padding: 20px 0;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      justify-content: space-around;
      flex-flow: wrap;
    }
  }
</style>
